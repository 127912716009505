import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Reassurance from '../components/static/rea';
import SEO from '../components/seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import styles from '../modules/sopk.module.scss';
import headsetImage from '../images/sopk/headset.svg';
import video from '../images/sopk/reduced_video_page_sopk.mp4';
import CartContext, { products } from '../context/CartContext';
import { navigate } from 'gatsby';
import arrowLeft from '../images/sopk/black-arrow-left.png';
import arrowRight from '../images/sopk/black-arrow-right.png';

import Avis from '../components/sopk/avis';
import PodcastCard from '../components/sopk/podcast-card';
import ArticleCard from '../components/sopk/article-card';
import HandBandItem from '../components/sopk/handband-item';
import GuidePopup from '../components/sopk/guide-popup';
import Composition from '../components/sopk/composition';

function SOPK() {
  const [selectedOption, setSelectedOption] = useState('');
  const [purchaseBtnText, setPurchaseBtnText] = useState("S'abonner");
  const { cart, setCart } = useContext(CartContext);
  const [error_abo, setErrorAbo] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    tags: ['sopk24'],
  });
  const [isCoverVisible, setIsCoverVisible] = useState(true);
  const [formErrors, setFormErrors] = useState({ email: '' });
  const [isVisible, setIsVisible] = useState(false);

  const videoRef = useRef(null);
  const handBandRef = useRef(null);
  const refs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);

  //For the mobile mode, we have an horizontal scroll so that the pictures of some sections are cut in the middle like in the XD
  useEffect(() => {
    refs.current.forEach((ref) => {
      if (ref.current) {
        const scrollPosition = ref.current.scrollWidth * 0.2;
        ref.current.scrollLeft = scrollPosition;
      }
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (handBandRef.current) {
        const container = handBandRef.current;
        let newScrollPosition = container.scrollLeft + container.offsetWidth;

        if (newScrollPosition <= container.scrollWidth - container.offsetWidth) {
          container.scrollLeft = newScrollPosition;
        } else {
          setTimeout(() => {
            container.scrollLeft = 0;
          }, 3000);
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const closeModal = () => setIsVisible(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const {
    pregnant_women,
    trustpilot_stars,
    opening,
    closing,
    guideiphone,
    podcastimg,
    buy_pack_SOPK,
    pageSopkYaml,
    video_cover,
    trustpilot_logo,
    BM_stars,
    guideiphone2,
  } = useStaticQuery(graphql`
    query SOPKPageQuery {
      pregnant_women: file(relativePath: { eq: "sopk/enceinte1.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      trustpilot_stars: file(relativePath: { eq: "sopk/trustpilot_stars.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      opening: file(relativePath: { eq: "sopk/opening.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      closing: file(relativePath: { eq: "sopk/closing.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      guideiphone: file(relativePath: { eq: "sopk/image-Guide-SOPK-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      guideiphone2: file(relativePath: { eq: "sopk/image-Guide-SOPK-Mobile.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      podcastimg: file(relativePath: { eq: "sopk/podcast.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      buy_pack_SOPK: file(relativePath: { eq: "sopk/buy_pack_SOPK.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      video_cover: file(relativePath: { eq: "sopk/video_cover.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      trustpilot_logo: file(relativePath: { eq: "sopk/trustpilot_logo.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      BM_stars: file(relativePath: { eq: "sopk/BM_stars.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      pageSopkYaml {
        partners {
          alt
          icon {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        diets {
          title
          content
          items {
            id
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
        avis {
          name
          text
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        podcasts {
          title
          items {
            id
            title
            desc
            audio_url {
              publicURL
            }
          }
        }
        articles {
          title
          items {
            title
            desc
            link
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
        complements {
          title
          content
          items {
            alt
            class
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
        recipes {
          title
          content
          items {
            alt
            class
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
        packOptions {
          logo_klarna {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          items {
            id
            duration
            price
            savings
            details
            subtitle
          }
        }
        handBandItems {
          image {
            publicURL
          }
          description
        }
        socialMediaLinks {
          title
          items {
            id
            url
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            alt
          }
        }
      }
    }
  `);

  //selects the pack option
  const handleChange = (event) => {
    const value = typeof event === 'string' ? event : event.target.value;
    setSelectedOption(value);
    setPurchaseBtnText(value === 'six_months' ? 'Acheter' : "S'abonner");
  };

  useEffect(() => {
    setCart('add', 'program', 'sopk');
    // Parse the URL
    const params = new URLSearchParams(window.location.search);
    const utmMedium = params.get('utm_medium');

    // Check if utm_medium=popup is present
    if (utmMedium === 'sopk24') {
      setFormData({ ...formData, tags: [...formData.tags, 'sopk24'] });
    }
  }, []);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChangeGuide = (e) => {
    const { name, value } = e.target;

    if (name === 'email') {
      if (!validateEmail(value)) {
        setFormErrors({ ...formErrors, email: 'Email non valide' });
      } else {
        setFormErrors({ ...formErrors, email: '' });
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  //When we click so get the SOPK guide
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setFormErrors({ ...formErrors, email: 'Email non valide' });
      return;
    }

    try {
      await fetch(process.env.GATSBY_SOPK24, {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify(formData),
      });
      document.getElementById('myform').reset();
      window.open('https://www.bemum.co/Guide_SOPK.pdf', '_blank');
      setIsVisible(false);
    } catch (error) {
      console.log(error);
      setIsVisible(false);
    }
  };

  const handleSubscribe = () => {
    if (selectedOption) {
      setCart('add', 'membershipPeriod', selectedOption);
      navigate('/panier/');
    } else {
      setErrorAbo(true);
    }
  };

  //For the image covering the video
  const handleCoverClick = () => {
    setIsCoverVisible(false);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const scroll = (direction) => {
    const container = refs.current[4].current;
    const scrollAmount = 200;
    if (direction === 'left') {
      container.scrollLeft -= scrollAmount;
    } else {
      container.scrollLeft += scrollAmount;
    }
  };

  return (
    <div className={styles.layout}>
      <div className={styles.wrapper}>
        <header>
          <Link to="/sopk" className={styles.logo} />
        </header>
        <main style={{ maxWidth: '100vw', overflow: 'hidden' }}>{}</main>
      </div>
      <GuidePopup
        modalOpen={isVisible}
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        handleChangeGuide={handleChangeGuide}
        guideiphone={guideiphone}
        guideiphone2={guideiphone2}
        formErrors={formErrors}
      />
      <SEO title={'SOPK'} description={'Votre accompagnement spécialisé dans le SOPK'} />
      <section className={styles.intro}>
        <div className={styles.text_intro}>
          <h1 className={styles.h1}>
            Le traitement de première intention du <b>SOPK</b> est une <strong>alimentation adaptée.</strong>
          </h1>
          <p className={styles.subtitle}>BeMum, le premier programme de fertilité pour les femmes atteintes de SOPK</p>
          <hr className={styles.bar} />
          <ul className={styles.list}>
            <li>UN COACHING AVEC UNE DIÉTÉTICIENNE</li>
            <li>DES RECETTES PROFERTILITÉ</li>
            <li>DES COMPLÉMENTS ALIMENTAIRES FABRIQUÉS EN FRANCE</li>
          </ul>
          <button className={`${styles.btn} ${styles.intro_btn}`}>
            <a href="#buy">Je commence l’aventure</a>
          </button>
        </div>
        <div className={styles.pregnant_women_container}>
          <GatsbyImage
            image={pregnant_women.childImageSharp.gatsbyImageData}
            alt="femme enceinte"
            className={styles.pregnant_women}
          />
        </div>
      </section>
      <section className={styles.talk_about_us}>
        <h2 className={styles.talk_about_us_title}>Ils parlent de nous</h2>
        <div className={styles.logos}>
          {pageSopkYaml.partners.map((partner, index) => (
            <GatsbyImage
              image={partner.icon.childImageSharp.gatsbyImageData}
              alt={partner.alt}
              className={styles.logo}
              key={index}
            />
          ))}
        </div>
      </section>
      <section className={styles.video}>
        {isCoverVisible ? (
          <div onClick={handleCoverClick}>
            <GatsbyImage
              image={video_cover.childImageSharp.gatsbyImageData}
              alt="Video sopk"
              className={styles.video_cover}
            />
          </div>
        ) : (
          <video className={styles.video} controls autoPlay ref={videoRef}>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}

        <button className={`${styles.btn} ${styles.btn_video}`}>
          <a href="#buy">Je commence l’aventure</a>
        </button>
      </section>

      <section className={styles.trustpilot}>
        <a href="https://fr.trustpilot.com/review/bemum.co" target="_blank" className={styles.widget}>
          Voir nos avis sur
          <GatsbyImage
            image={trustpilot_logo.childImageSharp.gatsbyImageData}
            alt="Logo trustpilot"
            className={styles.trustpilot_logo}
          />
          <span className={styles.trustpilot_note}>Excellent 4,7 sur 5</span>
          <GatsbyImage
            image={BM_stars.childImageSharp.gatsbyImageData}
            alt="Etoiles BeMum"
            className={styles.BM_stars}
          />
        </a>

        <button onClick={() => scroll('left')} className={styles.scrollButtonLeft}>
          <img src={arrowLeft} alt="Scroll Left" className={styles.scrollButtonImage} />
        </button>

        {/* SECTION Feedback */}
        <div className={styles.container_avis} ref={refs.current[4]}>
          {pageSopkYaml.avis.map((avis, index) => (
            <Avis
              key={index}
              avis={avis}
              trustpilotStarsImage={trustpilot_stars.childImageSharp.gatsbyImageData}
              openingImage={opening.childImageSharp.gatsbyImageData}
              closingImage={closing.childImageSharp.gatsbyImageData}
            />
          ))}
        </div>

        <button onClick={() => scroll('right')} className={styles.scrollButtonRight}>
          <img src={arrowRight} alt="Scroll Right" className={styles.scrollButtonImage} />
        </button>
      </section>

      <section>
        <div className={styles.container_img_pack}>
          <a href="#coaching" className={styles.packimg1}></a>
          <p className={styles.plus}>+</p>
          <a href="#recipes" className={styles.packimg2}></a>
          <p className={styles.plus}>+</p>
          <a href="#complements" className={styles.packimg3}></a>
        </div>
      </section>

      <section className={styles.buy} id="buy">
        <p className={`${styles.pack_price} ${styles.pack_mobile}`}>
          Retrouvez votre pack <span>SOPK</span>
        </p>
        <GatsbyImage
          image={buy_pack_SOPK.childImageSharp.gatsbyImageData}
          alt="Le pack SOPK"
          className={styles.buy_img}
        />
        <div className={styles.buy_text}>
          <p className={`${styles.pack_price} ${styles.pack_desktop}`}>
            Retrouvez votre pack <span>SOPK</span>
          </p>
          <div className={styles.img_stars_buy}>
            <GatsbyImage image={trustpilot_stars.childImageSharp.gatsbyImageData} alt="5 étoiles trustpilot" />
          </div>
          <ul className={styles.list_buy}>
            <li>UN COACHING AVEC UNE DIÉTÉTICIENNE</li>
            <li>DES RECETTES PROFERTILITÉ</li>
            <li>DES COMPLÉMENTS ALIMENTAIRES FABRIQUÉS EN FRANCE</li>
          </ul>

          <form className={styles.buy_form}>
            {pageSopkYaml.packOptions.items.map((option, index) => (
              <div
                key={index}
                className={`${styles.buy_inputs} ${selectedOption === option.id ? styles.selectedBackground : ''}`}
                onClick={() => handleChange(option.id)}
              >
                <div className={styles.radio_legende}>
                  <input
                    type="radio"
                    id={option.id}
                    name="fav_language"
                    value={option.id}
                    onChange={() => handleChange(option.id)}
                    checked={selectedOption === option.id}
                  />
                  <label htmlFor={option.id}>
                    <span className={styles.buy_inputs_price_text}>{option.duration}</span>
                    <span className={styles.buy_inputs_details}>{option.details}</span>
                    <br />
                    <span className={styles.buy_inputs_price_text}>{option.price}</span>
                    <span className={styles.buy_inputs_details}>{option.savings}</span>
                  </label>

                  {option.id === 'six_months' && (
                    <div className={styles.logo_clarna_container}>
                      <GatsbyImage
                        image={pageSopkYaml.packOptions.logo_klarna.childImageSharp.gatsbyImageData}
                        alt="logo Klarna"
                        className={styles.logo_klarna}
                      />
                    </div>
                  )}
                </div>
                {option.subtitle && <span className={styles.buy_inputs_subtitle}>{option.subtitle}</span>}
              </div>
            ))}
          </form>
          <button className={`${styles.btn} ${styles.abo}`} onClick={handleSubscribe}>
            {purchaseBtnText}
          </button>
          {error_abo && <p className={styles.error_msg}>Veuillez sélectionnez un abonnement</p>}

          <div ref={handBandRef} className={styles.handBandContainer}>
            {pageSopkYaml.handBandItems.map((item, index) => (
              <HandBandItem key={index} item={item} />
            ))}
          </div>
          <Composition />
        </div>
      </section>
      <section id="coaching" className={styles.coaching}>
        <div className={styles.suivi}>
          <div className={styles.suivi_img} ref={refs.current[0]}>
            {pageSopkYaml.diets.items.map((diet, index) => (
              <GatsbyImage
                key={index}
                image={diet.image.childImageSharp.gatsbyImageData}
                alt={diet.alt}
                className={`${styles[diet.id]}`}
              />
            ))}
          </div>
          <div className={styles.suivi_text}>
            <h2>{pageSopkYaml.diets.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: pageSopkYaml.diets.content }} />
          </div>
        </div>
      </section>
      <section className={`${styles.app}`} id="recipes">
        <div className={styles.app_col1}>
          <h2 className={styles.app_title}>{pageSopkYaml.recipes.title}</h2>
          <p className={styles.app_text} dangerouslySetInnerHTML={{ __html: pageSopkYaml.recipes.content }} />
        </div>
        <div className={styles.app_col2} ref={refs.current[1]}>
          {pageSopkYaml.recipes.items.map((item) => (
            <GatsbyImage
              image={item.image.childImageSharp.gatsbyImageData}
              alt={item.alt}
              className={styles[item.class]}
            />
          ))}
        </div>
      </section>
      <section className={` ${styles.complements}  ${styles.desktop}`} id="complements">
        <div className={styles.complements_col1} ref={refs.current[2]}>
          {pageSopkYaml.complements.items.map((item) => (
            <GatsbyImage
              image={item.image.childImageSharp.gatsbyImageData}
              alt={item.alt}
              className={styles[item.class]}
            />
          ))}
        </div>
        <div className={styles.complements_col2}>
          <h2 className={styles.complements_title}> {pageSopkYaml.complements.title}</h2>
          <p
            className={styles.complements_text}
            dangerouslySetInnerHTML={{ __html: pageSopkYaml.complements.content }}
          />
        </div>
      </section>

      <div className={styles.btn_complement}>
        <button className={`${styles.btn_cplmt} ${styles.btn}`}>
          <a href="#buy">Je commence l’aventure</a>
        </button>
      </div>

      <section>
        <h2 className={styles.podcast_header}>{pageSopkYaml.podcasts.title}</h2>
        <div className={styles.podcast_container}>
          {pageSopkYaml.podcasts.items.map((podcast, index) => (
            <PodcastCard key={index} podcast={podcast} podcastImage={podcastimg} headsetImage={headsetImage} />
          ))}
        </div>
      </section>
      <section>
        <h2 className={styles.article_header}>{pageSopkYaml.articles.title}</h2>
        <div className={styles.article_container}>
          {pageSopkYaml.articles.items.map((article, index) => (
            <ArticleCard key={index} article={article} index={index} />
          ))}
        </div>
      </section>
      <section className={styles.center}>
        <h2 className={styles.RS_header}>{pageSopkYaml.socialMediaLinks.title}</h2>
        <div className={styles.RS_imgs} ref={refs.current[3]}>
          {pageSopkYaml.socialMediaLinks.items.map((link, index) => (
            <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className={styles.RS_img_link}>
              {link.image && (
                <GatsbyImage
                  image={link.image.childImageSharp.gatsbyImageData}
                  alt={link.alt}
                  className={styles.RS_img}
                />
              )}
            </a>
          ))}
        </div>
      </section>
      <section className={styles.RDV_container}>
        <div className={styles.RDV_text}>
          <h2>Pour en savoir davantage, prenez rendez-vous avec nos experts</h2>
          <a href="https://calendly.com/bemum/15min" target="_blank">
            <button className={`${styles.btn} ${styles.btn_rdv}`}>Je prends un RDV</button>
          </a>
        </div>
      </section>
      <Reassurance />
    </div>
  );
}

export default SOPK;
